
import React from 'react'


export default function IndexPage() {
	return <main className='Page'>

		<p>Coming soon</p>

	</main>
}
